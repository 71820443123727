import React, {
  useState,
  useEffect,
  createContext,
  MutableRefObject,
  useCallback,
  useMemo,
} from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import {
  RemoveFromShortlistMutation,
  SaveToShortlistMutation,
  ShortlistQuery,
} from '../../generated/graphql';
import { SHORTLIST_QUERY_PARAM } from '../../constants/query-params';
import useQueryParams from '../../hooks/use-query-params';
import AD_COMPONENTS from '../../constants/ad-components';

type ContextState = {
  loading: boolean;
  addToShortlist?: (adId: number) => void;
  removeFromShortlist?: (adId: number) => void;
  registerScrollRef?: (
    adId: string,
    ref: MutableRefObject<HTMLDivElement | null>,
  ) => void;
};

type ShortlistContextProps = ContextState & {
  adIdList: number[];
};

// Partial means the same as if we add ? to each field
export const ShortlistContext = createContext<ShortlistContextProps>({
  loading: true,
  addToShortlist: undefined,
  removeFromShortlist: undefined,
  registerScrollRef: undefined,
  adIdList: [],
});

export const USER_SHORTLIST_QUERY = gql`
  query Shortlist {
    me {
      shortlist {
        id
        listingId
      }
    }
  }
`;

export const SAVE_SHORTLIST_MUTATION = gql`
  mutation saveToShortlist($adIds: [Int!]!) {
    saveShortlistListing(adIds: $adIds)
  }
`;
export const REMOVE_SHORTLIST_MUTATION = gql`
  mutation removeFromShortlist($adIds: [Int!]!) {
    removeShortlistListing(adIds: $adIds)
  }
`;

interface ProviderProps {
  children: React.ReactNode;
  authRedirectUrl?: string;
}

const defaultProps = {
  authRedirectUrl: '/authentications/login',
};

export const ShortlistProvider = ({
  children,
  authRedirectUrl,
}: ProviderProps): JSX.Element => {
  const [adIdList, setAdIdList] = useState<number[]>([]);
  // Used to scroll to adId if it is added via a link query param
  const [adIdRefMap, setAdIdRefMap] = useState<
    Record<number | string, MutableRefObject<HTMLDivElement | null>>
  >({});

  const [contextState, setContextState] = useState<ContextState>({
    loading: true,
    addToShortlist: undefined,
    removeFromShortlist: undefined,
    registerScrollRef: undefined,
  });

  // Latch so that a deferred listing is only added once
  const [hasAddedDeferredListing, setHasAddedDeferredListing] =
    useState<boolean>(false);

  const { data, loading, refetch } = useQuery<ShortlistQuery>(
    USER_SHORTLIST_QUERY,
    {
      ssr: false,
    },
  );
  const [saveShortlist] = useMutation<SaveToShortlistMutation>(
    SAVE_SHORTLIST_MUTATION,
  );
  const [removeShortlist] = useMutation<RemoveFromShortlistMutation>(
    REMOVE_SHORTLIST_MUTATION,
  );

  let authError = false;
  if (!data?.me) {
    authError = true;
  }

  useEffect(() => {
    const newAdIds =
      data?.me?.shortlist?.map(({ listingId }) => listingId) || [];
    setAdIdList(newAdIds);
  }, [data?.me?.shortlist]);

  // Handlers
  const registerScrollRef = useCallback(
    (adId: string, ref: MutableRefObject<HTMLDivElement | null>) => {
      if (adIdRefMap[adId] !== ref) {
        const newRef = { [adId]: ref };
        setAdIdRefMap({ ...adIdRefMap, ...newRef });
      }
    },
    [adIdRefMap],
  );

  const addToShortlist = useCallback(
    async (adId: number) => {
      // Optimistic update to list
      setAdIdList([...adIdList, adId]);

      // So that updates aren't blocked on mutations
      saveShortlist({ variables: { adIds: [adId] } })
        .catch(() => {
          // Revert to previous state on failure
          setAdIdList(adIdList.filter((id) => id !== adId));
        })
        .finally(() => {
          refetch();
        });
    },
    [adIdList, refetch, saveShortlist],
  );

  const removeFromShortlist = useCallback(
    async (adId: number) => {
      // Optimistic update to list
      setAdIdList(adIdList.filter((id) => id !== adId));

      // So that updates aren't blocked on mutations
      removeShortlist({ variables: { adIds: [adId] } })
        .catch(() => {
          // Revert to previous state on failure
          setAdIdList(adIdList.filter((id) => id !== adId));
        })
        .finally(() => {
          refetch();
        });
    },
    [adIdList, refetch, removeShortlist],
  );

  // Value (shared state) which is passed to hook
  const queryParams = useQueryParams();
  useEffect(() => {
    if (!authError) {
      // User is logged in and can use shortlist features
      const newState = {
        loading,
        addToShortlist,
        removeFromShortlist,
        registerScrollRef,
      };
      setContextState(newState);
    }
    if (!loading && authError && authRedirectUrl) {
      // User is not logged in
      const newState = {
        loading: false,

        // If they click shortlist -> login -> they will return to page with listing shortlisted
        addToShortlist: (adId: number) => {
          const currentUrl = new URL(window.document?.location?.toString());
          // This sets the param that tells us to add the listing to shortlist when we
          // return to this URL
          currentUrl.searchParams.set(SHORTLIST_QUERY_PARAM, `${adId}`);
          const loginAndReturnUrl = `${authRedirectUrl}?ReturnUrl=${currentUrl}`;
          window.location.href = loginAndReturnUrl;
        },
      };

      setContextState(newState);
    }
  }, [
    addToShortlist,
    authError,
    authRedirectUrl,
    loading,
    registerScrollRef,
    removeFromShortlist,
  ]);

  const adIdString = queryParams[SHORTLIST_QUERY_PARAM];
  const deferredShortlistAdId = adIdString
    ? parseInt(adIdString as string, 10)
    : undefined;
  useEffect(() => {
    // Logic for deferred adding of a shortlist item
    if (
      !authError &&
      deferredShortlistAdId &&
      !hasAddedDeferredListing &&
      // Small sanity checks to make sure valid AdId
      // And that we haven't already added it to the shortlist
      Number.isInteger(deferredShortlistAdId) &&
      deferredShortlistAdId > 0 &&
      !adIdList.includes(deferredShortlistAdId) // Prevents infinite loop
    ) {
      addToShortlist(deferredShortlistAdId);
      setHasAddedDeferredListing(true); // Prevents listing from being stuck on shortlist if navigated to via deferred add return url

      // Scroll to the listing if it is on the page.
      // Note there could be multiple (eg. next open home card + for sale card)
      // so we look for all possible listing cards on the page
      const listingCardsOnPage = Object.values(AD_COMPONENTS).reduce<
        HTMLDivElement[]
      >((accumulator, adComponent) => {
        const element =
          adIdRefMap[`${deferredShortlistAdId}-${adComponent}`]?.current;

        if (element) {
          accumulator.push(element);
        }

        return accumulator;
      }, []);

      // Then we scroll to the first one
      if (listingCardsOnPage.length) {
        listingCardsOnPage[0].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }
  }, [
    adIdRefMap,
    addToShortlist,
    authError,
    deferredShortlistAdId,
    hasAddedDeferredListing,
    adIdList, // Need this to check when the shortlist is updated for the "checkIsOnShortlist" check
  ]);

  const shortlistContextValue = useMemo(
    () => ({
      ...contextState,
      adIdList,
    }),
    [contextState, adIdList],
  );

  return (
    <ShortlistContext.Provider value={shortlistContextValue}>
      {children}
    </ShortlistContext.Provider>
  );
};

ShortlistProvider.defaultProps = defaultProps;
