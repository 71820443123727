import { getMixpanel, handleError } from '@domain-group/fe-helper';

interface BaseProps {
  source?: string;
  primaryCategory?: string;
  subCategory1?: string;
  subCategory2?: string;
  pageName?: string;
  pageType?: string;
  agencyId?: string;
  agencyName?: string;
  agentId?: string;
  agentName?: string;
  postcode?: string;
  suburb?: string;
  state?: string;
  lastTouchUTMSource?: string;
  lastTouchUTMMedium?: string;
  lastTouchUTMCampaign?: string;
  lastTouchUTMContent?: string;
}

export const getBaseProps = (): BaseProps => {
  if (typeof window !== 'undefined') {
    try {
      const page = global?.window?.digitalData?.page;

      const pageInfo = page?.pageInfo;
      const category = page?.category;

      const urlParams = new URLSearchParams(window?.location?.search);

      return {
        // Note that some properties are set initially in the register event (See use-mixpanel.ts)
        source: pageInfo?.source,
        primaryCategory: category?.primaryCategory,
        subCategory1: category?.subCategory1,
        subCategory2: category?.subCategory2,
        pageName: pageInfo?.pageName,
        pageType: category?.pageType,
        // Only applicable on agency/agent profiles
        agencyId: pageInfo?.agencyId,
        agencyName: pageInfo?.agencyName,
        postcode: pageInfo?.postcode ? `${pageInfo?.postcode}` : undefined,
        suburb: pageInfo?.suburb,
        state: pageInfo?.state,
        // Only applicable on agent profile
        agentId: pageInfo?.agentId ? `${pageInfo?.agentId}` : undefined, // contactId (eg. "1087648")
        agentName: pageInfo?.agentName,
        // UTM Data
        lastTouchUTMSource: urlParams?.get?.('utm_source') ?? undefined,
        lastTouchUTMMedium: urlParams?.get?.('utm_medium') ?? undefined,
        lastTouchUTMCampaign: urlParams?.get?.('utm_campaign') ?? undefined,
        lastTouchUTMContent: urlParams?.get?.('utm_content') ?? undefined,
      };
    } catch (err) {
      handleError(err);
    }
  }

  return {};
};

export const sendToMixpanel = (
  name: string,
  additionalProps: any = {},
  baseProps = getBaseProps(),
): void => {
  try {
    const mixpanel = getMixpanel();
    mixpanel.track(name, { ...baseProps, ...additionalProps });
  } catch (err) {
    if (typeof window !== 'undefined') {
      handleError(err);
    }
  }
};
