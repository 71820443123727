import { sendGroupStats } from '@domain-group/fe-helper';
import { sendToMixpanel } from '../../mixpanel';
import { getAgencyDetails, getPageDetails } from '../../utils';

interface TrackSubmitOptions {
  locationId: string;
}

const trackSubmit = ({ locationId }: TrackSubmitOptions): void => {
  sendToMixpanel('Email Enquiry', {
    enquiryCTA: 'Agency Profile - Enquiry Form',
  });

  const { agencyId } = getAgencyDetails();
  const { pageName } = getPageDetails();

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/EmailEnquiry/2.0.7-schema.json
  sendGroupStats({
    // eventVersion: '2.0.7',
    platform: 'Website' as any,
    eventType: 'EmailEnquiry',
    eventCategory: 'lead.email',
    sourceEntityType: 'Agency',
    sourceEntityId: agencyId,
    eventSource: 'AgencyDetails',
    eventSourceDetails: pageName,
    locationId,
    eventProvider: 'fe-ops-agency-server',
    teamName: 'Seller',
  });
};

export default trackSubmit;
