import { sendGroupStats } from '@domain-group/fe-helper';
import { getAgentDetails, getPageDetails } from '../../utils';
import { sendToMixpanel } from '../../mixpanel';

const trackSubmit = (data: {
  intent: string;
  postcode: string;
  message?: string | null;
  phone?: string | null;
}): void => {
  const { contactId } = getAgentDetails();
  const { pageName, pageType } = getPageDetails();

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/EmailEnquiry/2.0.7-schema.json
  sendGroupStats({
    // eventVersion: '2.0.7',
    eventType: 'EmailEnquiry',
    eventCategory: 'lead.email',
    sourceEntityType: 'Agent',
    sourceEntityId: contactId,
    eventSource: 'AgentDetails',
    eventSourceDetails: pageName,
    locationId: undefined,
    eventProvider: 'fe-ops-agency-server',
    teamName: 'Seller',
    platform: 'Website' as any,
  });

  const { intent, phone, postcode: enquiryPostcode, message } = data;

  sendToMixpanel('Email Enquiry', {
    enquiryLocation: pageType,
    enquiryFormType: 'inline',
    enquiryCTA: `${pageType} Inline`,
    enquiryIntentSelections: [intent],
    enquiryPostcode,
    enquiryPhoneProvided: !!phone,
    hasMessage: !!message,
  });
};

export default trackSubmit;
