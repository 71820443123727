import { pushToDataLayer } from '@domain-group/fe-helper';

import * as ga from '../../../constants/ga';

const trackSocialLink = ({ eventAction, eventLabel }) =>
  pushToDataLayer({
    event: ga.event,
    eventAction: `Agency Profile - ${eventAction}`,
    eventCategory: ga.category.OUTBOUND_LINK,
    eventLabel,
  });

export default trackSocialLink;
