import React from 'react';
import PropTypes from 'prop-types';

export type ServerInfo = {
  sysEnv: string; // 'desktop' | 'mobile' this is causing too much pain (e.g. failing tests in CI) :-/
  baseUrl: string;
  path: string;
  source: string;
  isEmbeddedApp: boolean;
  trackingDisabled: boolean;
  noIndexFollow?: boolean;
  seoPaginationNext?: string;
  seoPaginationPrev?: string;
  contactId?: string;
};

export default React.createContext({} as ServerInfo);

// 1:1 mapping of the TS types for consumers on JS
export const propTypes = {
  sysEnv: PropTypes.oneOf(['desktop', 'mobile']),
  baseUrl: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  isEmbeddedApp: PropTypes.bool.isRequired,
  trackingDisabled: PropTypes.bool.isRequired,
  noIndexFollow: PropTypes.bool,
  seoPaginationNext: PropTypes.string,
  seoPaginationPrev: PropTypes.string,
  contactId: PropTypes.string,
};
