import { pushToDataLayer } from '@domain-group/fe-helper';

const trackEnquirySubmitError = ({ contactId, name }) => {
  pushToDataLayer({
    event: 'GAevent',
    eventAction: 'Email Enquiry Unsuccessful',
    eventCategory: 'Interaction',
    eventLabel: `${name} - ${contactId}`,
  });
};

export default trackEnquirySubmitError;
