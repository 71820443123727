import { getMixpanel } from '@domain-group/fe-helper';
import { trackCTAEntryToFunnel } from '@domain-group/fe-co-agent-showcase';

export const entryPoint = 'Find Agents';

const trackAppraisalFunnelEntry = (): void => {
  trackCTAEntryToFunnel();

  try {
    const mixpanel = getMixpanel();
    mixpanel.track('Request Appraisal', {
      entryPoint,
    });
  } catch (err) {
    console.error(err);
  }
};

export default trackAppraisalFunnelEntry;
