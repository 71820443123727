// import { pushToDataLayer } from '@domain-group/fe-helper';

export const trackViewMore = (): void => {
  // TODO: Create event schema for tracking these
  // pushToDataLayer({
  //   event: 'GAevent',
  //   eventCategory: 'Membership',
  //   eventAction: 'View more guides opened',
  //   eventLabel: 'Owners guides',
  // });
};

export default trackViewMore;
