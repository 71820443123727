import { sendGroupStats } from '@domain-group/fe-helper';
import { sendToMixpanel } from '../..';
import { getAgentDetails, getPageDetails } from '../../utils';

const trackPageViewAgentProfile = ({
  locationId,
}: {
  locationId: string;
}): void => {
  const { contactId } = getAgentDetails();
  const { pageName } = getPageDetails();

  // Group Stats page view event
  // TODO: The schema for this event is way off from the actual event being sent
  // The description of the event in the Event Schema is "CTA Click to see agent profile page"
  // This is not a CTA click, it's a page view
  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/AgentDetailsView/2.0.3-schema.json
  sendGroupStats({
    // eventVersion: '2.0.3',
    platform: 'Website' as any,
    eventType: 'AgentDetailsView',
    eventCategory: 'takeaction.intent', // TODO: Should be 'view', but event schema doesn't allow it
    sourceEntityType: 'Agent', // Schema says the source entity type must be "Property" or "Listing", but those are incorrect
    sourceEntityId: contactId,
    eventSource: 'AgentDetails',
    eventSourceDetails: pageName, // Schema says the only accepted value is "AgentEnvelopeIcon", which is a bonkers value
    locationId,
    eventProvider: 'fe-ops-agency-server',
    teamName: 'Seller',
  });

  // MixPanel page view event
  sendToMixpanel('View Agent Profile');
};

export default trackPageViewAgentProfile;
