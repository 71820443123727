// Unique prefixes to differentiate the listing cards that can appear on a page.
// This is used to help set a unique ref ID for each listing card.
const AD_COMPONENTS = Object.freeze({
  NextOpenHome: 'next-open-home',
  CurrentAgencyListings: 'current-agency-listings',
  CurrentContactListings: 'current-contact-listings',
  ListingCard: 'listing-card-query',
});

export default AD_COMPONENTS;
