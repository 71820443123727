import { useMemo } from 'react';
import { GetServerSidePropsResult } from 'next';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import fetch from 'isomorphic-unfetch';
import { BatchHttpLink } from 'apollo-link-batch-http';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import getConfig from 'next/config';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export const getGQLUrl = (): string => {
  const { publicRuntimeConfig } = getConfig();
  const { GRAPHQL_URL, GRAPHQL_URL_CLIENT } = publicRuntimeConfig;

  const url = typeof window === 'undefined' ? GRAPHQL_URL : GRAPHQL_URL_CLIENT;
  return url;
};

export const initializeApollo = (initialState?: any) => {
  let innerApolloClient;
  if (apolloClient) {
    innerApolloClient = apolloClient;
  } else {
    const { publicRuntimeConfig } = getConfig();

    innerApolloClient = new ApolloClient({
      name: publicRuntimeConfig?.APP_NAME,
      version: publicRuntimeConfig?.VERSION,
      ssrMode: typeof window === 'undefined',
      link: new BatchHttpLink({
        uri: getGQLUrl(),
        credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
        fetch,
      }) as any,
      defaultOptions: {
        query: {
          errorPolicy: 'all', // https://www.apollographql.com/docs/react/v2/data/error-handling/#error-policies
        },
        mutate: {
          errorPolicy: 'all', // https://www.apollographql.com/docs/react/v2/data/error-handling/#error-policies
        },
      },
      cache: new InMemoryCache({
        // What this does:
        // If a 'type' does not have an 'id' field you may run into apollo cache issues, then you can either
        //  1. Add an `id` field in the GraphQL Server
        //  2. Add a type policy here on what to instead use as an id field

        typePolicies: {
          Address: {
            keyFields: ['displayAddress'],
          },
          Suburb: {
            keyFields: ['suburbId'],
          },
          ActiveSuburbDetails: {
            keyFields: ['suburbName'],
          },
        },
      }),
    });
  }

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = innerApolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((destinationData) =>
          sourceArray.every(
            (sourceData) => !isEqual(destinationData, sourceData),
          ),
        ),
      ],
    });

    // Restore the cache with the merged data
    innerApolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return innerApolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = innerApolloClient;
  }

  return innerApolloClient;
};

export const addApolloState = (
  client: any,
  pageProps: any,
): GetServerSidePropsResult<unknown> => {
  const apolloProps = {} as {
    [APOLLO_STATE_PROP_NAME]: any;
  };

  if (pageProps?.props) {
    apolloProps[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return {
    props: {
      ...pageProps?.props,
      ...apolloProps,
    },
  };
};

export const useApollo = (pageProps: {
  [APOLLO_STATE_PROP_NAME]?: any;
}): ApolloClient<NormalizedCacheObject> => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];

  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
};
