import { sendToMixpanel } from '../../mixpanel';

const trackEnquiryFormViewed = (): void => {
  // It's actually an "Agency Form", but this is consistent with other
  // events and follows the specification
  // https://domain.atlassian.net/browse/VX-1273
  sendToMixpanel('Display Contact Agent Form', {
    enquiryCTA: 'Agency Profile - Viewed Enquiry Form',
  });
};

export default trackEnquiryFormViewed;
