import { sendGroupStats, pushToDataLayer } from '@domain-group/fe-helper';
import { getPageDetails } from '../../utils';

const trackAgentCall = ({ contactId }) => {
  const { pageName } = getPageDetails();

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/PhoneEnquiry/2.0.6-schema.json
  sendGroupStats({
    // eventVersion: '2.0.6',
    eventType: 'PhoneEnquiry',
    eventCategory: 'lead.phone',
    sourceEntityType: 'Agency',
    sourceEntityId: contactId,
    eventSource: 'AgencyDetails',
    eventSourceDetails: pageName,
    eventProvider: 'fe-ops-agency-server',
    teamName: 'Seller',
    platform: 'Website',
  });

  pushToDataLayer({
    event: 'GAevent',
    eventCategory: 'Conversion',
    eventAction: 'Click to Reveal - Find an Agent',
    eventLabel: `Agency Profile - ${contactId}`,
  });
};

export default trackAgentCall;
