import { pushToDataLayer } from '@domain-group/fe-helper';

const gaActions = {
  AGENCY_PROFILE: 'Agency Profile',
  CONTACT_PROFILE: 'Agent Profile',
};

const gaCatagories = {
  INTERACTION: 'Interaction',
};

const gaLabels = {
  SALES_STATISTICS: 'Sale statistics',
};

const trackOnSelectSalesStatsSuburb = (suburbName, eventAction) =>
  pushToDataLayer({
    event: 'GAevent',
    eventAction,
    eventCategory: gaCatagories.INTERACTION,
    eventLabel: `${gaLabels.SALES_STATISTICS} - ${suburbName}`,
  });

// Agency Exports
export const agency = {
  onSelectSuburb: (suburbName) =>
    trackOnSelectSalesStatsSuburb(suburbName, gaActions.AGENCY_PROFILE),
};

// Contact Exports
export const contact = {
  onSelectSuburb: (suburbName) =>
    trackOnSelectSalesStatsSuburb(suburbName, gaActions.CONTACT_PROFILE),
};
