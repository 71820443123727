import { sendGroupStats } from '@domain-group/fe-helper';
import { getAgencyDetails, getPageDetails } from '../../utils';
import { sendToMixpanel } from '../../mixpanel';

const trackViewAll = (): void => {
  const { agencyId } = getAgencyDetails();
  const { subCategory1 } = getPageDetails();

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/VendorRecoViewAll/2.0.6-schema.json
  sendGroupStats(
    {
      // eventVersion: '2.0.1',
      platform: 'Website' as any,
      sourceEntityType: 'Agency',
      sourceEntityId: agencyId,
      eventSource: subCategory1,
      eventSourceDetails: 'VendorRecommendation',
      eventCategory: 'takeaction.intent',
      teamName: 'Seller',
      eventType: 'VendorRecoViewAll',
    },
    undefined, // URL (undefined uses default of /graphql)
    true, // Immediate
  );

  sendToMixpanel('Click View All Recommendations');
};

export default trackViewAll;
