import { sendGroupStats } from '@domain-group/fe-helper';
import { getPageDetails } from '../../utils';
import { sendToMixpanel } from '../../mixpanel';

const trackEnquirySubmit = ({
  contactId,
  intent,
  phone,
  postcode: enquiryPostcode,
  message,
}) => {
  const { pageName, pageType } = getPageDetails();

  const userIntent = intent || 'No Intent Provided';

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/EmailEnquiry/2.0.7-schema.json
  sendGroupStats({
    // eventVersion: '2.0.7',
    eventType: 'EmailEnquiry',
    eventCategory: 'lead.email',
    sourceEntityType: 'Agency',
    sourceEntityId: contactId,
    eventSource: 'AgencyDetails',
    eventSourceDetails: pageName,
    eventProvider: 'fe-ops-agency-server',
    teamName: 'Seller',
    platform: 'Website',
  });

  sendToMixpanel('Email Enquiry', {
    enquiryLocation: pageType,
    enquiryFormType: 'modal',
    enquiryCTA: `${pageType} - Our Team`,
    enquiryIntentSelections: [userIntent],
    enquiryPostcode,
    enquiryPhoneProvided: !!phone,
    hasMessage: !!message,
  });
};

export default trackEnquirySubmit;
