export const getAgencyDetails = (): {
  agencyId?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
} => {
  const agencyId = window?.digitalData?.page?.pageInfo?.agencyId;
  const suburb = window?.digitalData?.page?.pageInfo?.suburb;
  const state = window?.digitalData?.page?.pageInfo?.state;
  const postcode = window?.digitalData?.page?.pageInfo?.postcode;

  return {
    agencyId,
    suburb,
    state,
    postcode: postcode ? `${postcode}` : undefined,
  };
};

export const getAgentDetails = (): {
  contactName?: string;
  contactId?: string;
} => {
  const contactName = (window?.digitalData?.page?.pageInfo?.agentName || '')
    .split('-')[0]
    .trim(); // agentName has format `first last - location` in the dataLayer
  const contactId = window?.digitalData?.page?.pageInfo?.agentId;

  return { contactName, contactId: contactId ? `${contactId}` : undefined };
};

export const getPageDetails = (): {
  pageName?: string;
  source?: string;
  pageType?: string;
  primaryCategory?: string;
  subCategory1?: string;
  subCategory2?: string;
  siteSection: 'Owners';
  searchTerm?: string;
  searchDepth?: number;
} => {
  const pageName = window?.digitalData?.page?.pageInfo?.pageName;
  const source = window?.digitalData?.page?.pageInfo?.source;

  const pageType = window?.digitalData?.page?.category?.pageType;
  const primaryCategory = window?.digitalData?.page?.category?.primaryCategory;
  const subCategory1 = window?.digitalData?.page?.category?.subCategory1;
  const subCategory2 = window?.digitalData?.page?.category?.subCategory2;

  const siteSection = 'Owners';

  const searchTerm = window?.digitalData?.page?.pageInfo?.search?.searchTerm;
  const searchDepth = window?.digitalData?.page?.pageInfo?.search?.searchDepth;

  return {
    pageName,
    source,
    pageType,
    primaryCategory,
    subCategory1,
    subCategory2,
    siteSection,
    searchTerm,
    searchDepth,
  };
};
