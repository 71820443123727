import { pushToDataLayer } from '@domain-group/fe-helper';

import * as ga from '../../../constants/ga';

const trackSocialLink = () =>
  pushToDataLayer({
    event: ga.event,
    eventCategory: ga.category.OUTBOUND_LINK,
  });

export default trackSocialLink;
