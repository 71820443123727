import { pushToDataLayer, sendGroupStats } from '@domain-group/fe-helper';

import * as ga from '../../../constants/ga';
import { getAgentDetails, getPageDetails } from '../../utils';

const callButtonClick = ({ locationId }) => {
  const { contactId } = getAgentDetails();
  const { pageName } = getPageDetails();

  pushToDataLayer({
    event: ga.event,
    eventAction: 'Click to Reveal - Find an Agent',
    eventCategory: ga.category.CONVERSION,
    eventLabel: 'Agent Profile',
  });

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/PhoneEnquiry/2.0.6-schema.json
  sendGroupStats({
    // eventVersion: '2.0.6',
    eventType: 'PhoneEnquiry',
    eventCategory: 'lead.phone',
    sourceEntityType: 'Agent',
    sourceEntityId: contactId,
    eventSource: 'AgentDetails',
    eventSourceDetails: pageName,
    locationId,
    eventProvider: 'fe-ops-agency-server',
    teamName: 'Seller',
    platform: 'Website',
  });
};

export default callButtonClick;
