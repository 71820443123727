import { pushToDataLayer } from '@domain-group/fe-helper';
import { getAgentDetails } from '../../utils';

const trackSubmitError = (): void => {
  const { contactName, contactId } = getAgentDetails();

  pushToDataLayer({
    event: 'GAevent',
    eventAction: 'Email Enquiry Unsuccessful',
    eventCategory: 'Interaction',
    eventLabel: `${contactName} - ${contactId}`,
  });
};

export default trackSubmitError;
