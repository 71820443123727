/* eslint-disable react/prop-types */
import React from 'react';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { ServerInfoContext } from '../contexts';
import { useApollo, APOLLO_STATE_PROP_NAME } from '../lib/apollo';
import { useMixpanel } from '../tracking';
import trackWebVitals from '../helper/track-web-vitals';
import { UserDetailsRedacted } from '../@types';

export interface AppPageProps {
  [APOLLO_STATE_PROP_NAME]?: any;
  mixpanelToken: string;
  user?: UserDetailsRedacted;
  serverInfo: any;
}

interface AppType {
  Component: any;
  pageProps: AppPageProps;
}

const App = ({ Component, pageProps }: AppType): JSX.Element => {
  const apolloClient = useApollo(pageProps);

  const { mixpanelToken, user } = pageProps;

  const mixpanelHasInitialized = useMixpanel({
    mixpanelToken,
    userId: user?.userId,
    sessionId: user?.sessionId,
  });

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ApolloProvider client={apolloClient}>
        <ServerInfoContext.Provider value={pageProps.serverInfo}>
          <Component
            {...pageProps}
            mixpanelHasInitialized={mixpanelHasInitialized}
          />
        </ServerInfoContext.Provider>
      </ApolloProvider>
    </>
  );
};

export default App;

export const reportWebVitals = (metric: any): void => {
  trackWebVitals(metric);
};
