import { getPageDetails } from '../../utils';
import { sendToMixpanel } from '../../mixpanel';

const trackSelectIntent = (intent: string): void => {
  const { pageType } = getPageDetails();

  sendToMixpanel('Enquiry Form Intent Selected', {
    enquiryLocation: pageType,
    enquiryFormType: 'inline',
    enquiryCTA: `${pageType} Inline`,
    enquiryIntentSelections: [intent],
  });
};

export default trackSelectIntent;
