import { RecommendationPayload } from '../../../@types';

const trackPageRendered = (data: RecommendationPayload): HTMLImageElement => {
  /*
    Rate My Agent wants to fire an initialisation pixel before firing
    the other tracking pixels. The idea being that the init pixel will
    set a cookie and the other tracking pixels will use them. This is
    to prevent getting five unique visitors per page: because there are
    five reviews, there are five tracking pixels, hence five unique
    visitors.
    */

  const pixels = [] as string[];
  data?.recommendations?.reviews?.forEach((review) => {
    if (review?.agentReviewTrackingPixelUrl) {
      pixels.push(review.agentReviewTrackingPixelUrl);
    }
  });

  const initImg = new Image();
  initImg.onload = () => {
    pixels.forEach((pixelSrc) => {
      const trackingPixel = new Image();
      trackingPixel.src = pixelSrc;
    });
  };
  initImg.src = 'https://trixels.ratemyagent.com.au/trixel/init';

  return initImg;
};

export default trackPageRendered;
