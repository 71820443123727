import { sendGroupStats } from '@domain-group/fe-helper';
import { getAgencyDetails, getPageDetails } from '../../utils';

const trackModalNext = (): void => {
  const { agencyId } = getAgencyDetails();
  const { subCategory1 } = getPageDetails();

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/VendorRecoNextProperty/2.0.8-schema.json
  sendGroupStats({
    // eventVersion: '2.0.8',
    platform: 'Website' as any,
    sourceEntityType: 'Agency',
    sourceEntityId: agencyId,
    eventSource: subCategory1,
    eventSourceDetails: 'VendorRecommendation',
    eventCategory: 'takeaction.intent',
    eventType: 'VendorRecoNextProperty',
    targetEntityType: 'Listing',
    teamName: 'Seller',
  });
};

export default trackModalNext;
