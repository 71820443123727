const getEntryPoint = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const entryPoint = urlParams.get('entry-point');

  if (entryPoint) {
    return decodeURIComponent(entryPoint);
  }

  return 'No Entry Point Stored';
};

export default getEntryPoint;
