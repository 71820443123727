import { sendGroupStats, pushToDataLayer } from '@domain-group/fe-helper';
import { sendToMixpanel } from '../../mixpanel';
import { getAgentDetails, getPageDetails } from '../../utils';

const trackOnClick = ({ locationId }: { locationId: string }): void => {
  const { contactId } = getAgentDetails();
  const { pageName } = getPageDetails();

  // TODO: Event Schema for this event is off from the actual event being sent
  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/CTALeadsToFunnel/2.0.11-schema.json
  sendGroupStats({
    // eventVersion: '2.0.11',
    platform: 'Website' as any,
    eventType: 'CTALeadsToFunnel',
    eventCategory: 'takeaction.intent',
    sourceEntityType: 'Agent',
    sourceEntityId: contactId,
    eventSource: 'AgentDetails',
    eventSourceDetails: pageName,
    locationId,
    eventProvider: 'fe-ops-agency-server', // Not an accepted value according to schema
    teamName: 'Seller',
    targetEntityType: 'AppraisalFunnel',
  });

  sendToMixpanel('Request Appraisal', {
    entryPoint: 'Agent Profile',
  });

  pushToDataLayer({
    event: 'GAevent',
    eventCategory: 'Interaction',
    eventAction: 'CTA - Appraisal Funnel',
    eventLabel: 'Get a free appraisal',
  });
};

export default trackOnClick;
