import { sendToMixpanel } from '../../mixpanel';
import { getPageDetails } from '../../utils';

const trackAgentGetAppraisal = () => {
  const { pageType } = getPageDetails();

  sendToMixpanel('Display Contact Agent Form', {
    enquiryLocation: pageType,
    enquiryFormType: 'modal',
    enquiryCTA: `Agency Profile - Our Team`,
  });
};

export default trackAgentGetAppraisal;
