// import { pushToDataLayer } from '@domain-group/fe-helper';

const trackGuide = (): void => {
  // title: string
  // TODO: Create event schema for tracking these
  // pushToDataLayer({
  //   event: 'GAevent',
  //   eventCategory: 'Membership',
  //   eventAction: `${title} guide opened`,
  //   eventLabel: 'Owners guides',
  // });
};

export default trackGuide;
