import { pushToDataLayer } from '@domain-group/fe-helper';
import { getAgentDetails } from '../../utils';

const trackSubmitSuccess = ({ intent }: { intent: string }): void => {
  const { contactName, contactId } = getAgentDetails();

  pushToDataLayer({
    event: 'GAevent',
    eventAction: 'Email Enquiry Successful',
    eventCategory: 'Conversion',
    eventLabel: `${contactName} - ${contactId}`,
    dimension147: intent,
  });
};

export default trackSubmitSuccess;
