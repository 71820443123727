import { sendToMixpanel } from '../tracking';

const webVitalEnum = {
  // Standard Web Vitals (https://nextjs.org/docs/advanced-features/measuring-performance)
  TTFB: 'Web Vitals - Time to First Byte',
  FCP: 'Web Vitals - First Contentful Paint',
  LCP: 'Web Vitals - Largest Contentful Paint',
  FID: 'Web Vitals - First Input Delay',
  CLS: 'Web Vitals - Cumulative Page Shift',

  // Custom Nextjs web vitals (https://nextjs.org/docs/advanced-features/measuring-performance#custom-metrics)
  'Next.js-hydration': 'Web Vitals - Next.js Hydration',
  'Next.js-route-change-to-render':
    'Web Vitals - Next.js Route change to render',
  'Next.js-render': 'Web Vitals - Next.js Render',
};

function trackWebVitals(metric) {
  const window = global?.window;
  if (!window?.webVitalData) {
    window.webVitalData = {};
  }
  if (metric.label === 'web-vital' || metric.label === 'custom') {
    const webVitalName = webVitalEnum[metric.name];

    const msToSeconds = (milliseconds) => (milliseconds / 1000).toFixed(2);
    const value =
      metric.name === 'CLS' ? metric.value : msToSeconds(metric.value);

    window.webVitalData[webVitalName] = value;
  }

  const essentialVitalsCollected = () =>
    webVitalEnum.LCP in (window?.webVitalData ?? {}) &&
    webVitalEnum.FCP in (window?.webVitalData ?? {});

  if (essentialVitalsCollected()) {
    const pageName = window?.digitalData?.page?.pageInfo?.pageName;
    const webVitalData = window?.webVitalData;

    sendToMixpanel('FAA Web Performance', { ...webVitalData, pageName });
    window.webVitalData = {};
  }
}

export default trackWebVitals;
