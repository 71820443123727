import { pushToDataLayer } from '@domain-group/fe-helper';

import * as ga from '../../../constants/ga';

const agencyLogoClick = ({ url }) =>
  pushToDataLayer({
    event: ga.event,
    eventAction: 'Agency Profile - Logo',
    eventCategory: ga.category.OUTBOUND_LINK,
    eventLabel: url,
  });

export default agencyLogoClick;
