import { pushToDataLayer } from '@domain-group/fe-helper';

import * as ga from '../../../constants/ga';

const agencyAddressClick = (): void => {
  pushToDataLayer({
    event: ga.event,
    eventAction: 'Agency Profile',
    eventCategory: ga.category.INTERACTION,
    eventLabel: 'Agency Address Click',
  });
};

export default agencyAddressClick;
