import { pushToDataLayer } from '@domain-group/fe-helper';

const gaActions = {
  AGENCY_PROFILE: 'Agency Profile',
  CONTACT_PROFILE: 'Agent Profile',
};

const gaCatagories = {
  INTERACTION: 'Interaction',
};

const gaLabels = {
  LISTINGS_TAB: 'Listings Tab',
  LISTINGS_FILTER_CHANGE: 'Listings - Filter Change',
  LISTINGS_VIEW_MORE: 'Listings - View More',
  PROPERTY_CLICK: 'Property Click',
};

const listingStatusToGTMName = {
  SOLD: 'Sold',
  'FOR SALE': 'Sale',
  'FOR RENT': 'Rent',
  LEASED: 'Leased',
};

const getGTMName = (listingStatus) => {
  const listingStatusGTMName = listingStatusToGTMName[listingStatus];
  if (!listingStatusGTMName) {
    throw new Error(`Listing status GTM Name not exist for ${listingStatus}`);
  }
  return listingStatusGTMName;
};

const trackOnSelectListingStatus = (listingStatus, eventAction) =>
  pushToDataLayer({
    event: 'GAevent',
    eventAction,
    eventCategory: gaCatagories.INTERACTION,
    eventLabel: `${gaLabels.LISTINGS_TAB} - ${getGTMName(listingStatus)}`,
  });

const trackOnSortBy = (listingStatus, sortBy, eventAction) => {
  pushToDataLayer({
    event: 'GAevent',
    eventAction,
    eventCategory: gaCatagories.INTERACTION,
    eventLabel: `${gaLabels.LISTINGS_FILTER_CHANGE} ${getGTMName(
      listingStatus,
    )} - sortBy ${sortBy}`,
  });
};

const onFilterChange = (listingStatus, eventAction, filterChange) =>
  pushToDataLayer({
    event: 'GAevent',
    eventAction,
    eventCategory: gaCatagories.INTERACTION,
    eventLabel: `${gaLabels.LISTINGS_FILTER_CHANGE} ${getGTMName(
      listingStatus,
    )} - ${filterChange}`,
  });

const trackOnPropertyTypeChange = (listingStatus, eventAction) =>
  onFilterChange(listingStatus, eventAction, 'propertyType');

const trackOnSuburbChange = (listingStatus, eventAction) =>
  onFilterChange(listingStatus, eventAction, 'locationId');

const trackOnBedroomChange = (listingStatus, eventAction) =>
  onFilterChange(listingStatus, eventAction, 'bedrooms');

const trackOnViewMore = (listingStatus, eventAction) =>
  pushToDataLayer({
    event: 'GAevent',
    eventAction,
    eventCategory: gaCatagories.INTERACTION,
    eventLabel: `${gaLabels.LISTINGS_VIEW_MORE} ${getGTMName(listingStatus)}`,
  });

const trackOnListingCardClick = (listingStatus, eventAction) => {
  pushToDataLayer({
    event: 'GAevent',
    eventAction,
    eventCategory: gaCatagories.INTERACTION,
    eventLabel: `${gaLabels.PROPERTY_CLICK} - ${getGTMName(listingStatus)}`,
  });
};

// Agency Exports
export const agency = {
  onSelectListingStatus: (listingStatus) =>
    trackOnSelectListingStatus(listingStatus, gaActions.AGENCY_PROFILE),

  onSortBy: (listingStatus, sortBy) =>
    trackOnSortBy(listingStatus, sortBy, gaActions.AGENCY_PROFILE),

  onPropertyTypeChange: (listingStatus) =>
    trackOnPropertyTypeChange(listingStatus, gaActions.AGENCY_PROFILE),

  onSuburbChange: (listingStatus) =>
    trackOnSuburbChange(listingStatus, gaActions.AGENCY_PROFILE),

  onBedroomChange: (listingStatus) =>
    trackOnBedroomChange(listingStatus, gaActions.AGENCY_PROFILE),

  onViewMore: (listingStatus) =>
    trackOnViewMore(listingStatus, gaActions.AGENCY_PROFILE),

  onListingCardClick: (listingStatus) =>
    trackOnListingCardClick(listingStatus, gaActions.AGENCY_PROFILE),
};

// Contact Exports
export const contact = {
  onSortBy: (listingStatus, sortBy) =>
    trackOnSortBy(listingStatus, sortBy, gaActions.CONTACT_PROFILE),

  onSelectListingStatus: (listingStatus) =>
    trackOnSelectListingStatus(listingStatus, gaActions.CONTACT_PROFILE),

  onPropertyTypeChange: (listingStatus) =>
    trackOnPropertyTypeChange(listingStatus, gaActions.CONTACT_PROFILE),

  onSuburbChange: (listingStatus) =>
    trackOnSuburbChange(listingStatus, gaActions.CONTACT_PROFILE),

  onBedroomChange: (listingStatus) =>
    trackOnBedroomChange(listingStatus, gaActions.CONTACT_PROFILE),

  onViewMore: (listingStatus) =>
    trackOnViewMore(listingStatus, gaActions.CONTACT_PROFILE),

  onListingCardClick: (listingStatus) =>
    trackOnListingCardClick(listingStatus, gaActions.CONTACT_PROFILE),
};
