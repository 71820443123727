import { pushToDataLayer } from '@domain-group/fe-helper';

const trackEnquirySubmitSuccess = ({ intent, contactId, name }) => {
  pushToDataLayer({
    event: 'GAevent',
    eventAction: 'Email Enquiry Successful',
    eventCategory: 'Conversion',
    eventLabel: `${name} - ${contactId}`,
    dimension147: intent,
  });
};

export default trackEnquirySubmitSuccess;
