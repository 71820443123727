import { getMixpanel } from '@domain-group/fe-helper';
import { getBaseProps } from '..';

const trackViewLandingPage = (): void => {
  try {
    const mixpanel = getMixpanel();
    mixpanel.track('View FAA Landing Page', { ...getBaseProps() });
  } catch (err) {
    console.error(err);
  }
};

export default trackViewLandingPage;
