import { pushToDataLayer, sendGroupStats } from '@domain-group/fe-helper';
import { sendToMixpanel } from '../../mixpanel';
import * as ga from '../../../constants/ga';
import { getAgencyDetails, getPageDetails } from '../../utils';

const callButtonClick = () => {
  pushToDataLayer({
    event: ga.event,
    eventAction: 'Click to Reveal - Find an Agent',
    eventCategory: ga.category.CONVERSION,
    eventLabel: 'Agency Profile',
  });

  const { agencyId } = getAgencyDetails();
  const { pageName } = getPageDetails();

  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/PhoneEnquiry/2.0.6-schema.json
  sendGroupStats({
    // eventVersion: '2.0.6',
    eventType: 'PhoneEnquiry',
    eventCategory: 'lead.phone',
    sourceEntityType: 'Agency',
    sourceEntityId: agencyId,
    eventSource: 'AgencyDetails',
    eventSourceDetails: pageName,
    eventProvider: 'fe-ops-agency-server',
    teamName: 'Seller',
    platform: 'Website',
  });

  // It's actually an "Agency Phone", but this is consistent with other
  // events and follows the specification
  sendToMixpanel('Reveal Agent Phone', {
    enquiryCTA: 'Agency Profile - Hero Banner',
  });
};

export default callButtonClick;
