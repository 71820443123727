import { pushToDataLayer } from '@domain-group/fe-helper';

import * as ga from '../../../constants/ga';

const trackViewMore = () => {
  pushToDataLayer({
    event: ga.event,
    eventAction: 'Agency Profile',
    eventCategory: ga.category.INTERACTION,
    eventLabel: 'Agents - View More',
  });
};

export default trackViewMore;
