import { sendGroupStats } from '@domain-group/fe-helper';
import { sendToMixpanel } from '../..';
import { getAgencyDetails, getPageDetails } from '../../utils';

interface TrackPageViewAgencyProfileOptions {
  locationId: string;
}

const trackPageViewAgencyProfile = ({
  locationId,
}: TrackPageViewAgencyProfileOptions): void => {
  const { agencyId } = getAgencyDetails();
  const { pageName } = getPageDetails();

  // Group Stats page view event
  // https://github.com/domain-group/event-schemas/blob/2edb28b06c0fe488c9fb304d0834f24aaaa25f6d/schemas/group-stats/AgencyDetailsView/2.0.1-schema.json
  sendGroupStats({
    // eventVersion: '2.0.1',
    platform: 'Website' as any,
    eventType: 'AgencyDetailsView',
    eventCategory: 'takeaction.intent', // TODO: Should be 'view', but event schema doesn't allow it
    sourceEntityType: 'Agency',
    sourceEntityId: agencyId,
    eventSource: 'AgencyDetails',
    eventSourceDetails: pageName,
    locationId,
    eventProvider: 'fe-ops-agency-server',
    teamName: 'Seller',
  });

  // MixPanel page view event
  sendToMixpanel('View Agency Profile');
};

export default trackPageViewAgencyProfile;
