import { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { getDeviceType } from '@domain-group/fe-brary';
import { initMixpanel } from '@domain-group/fe-helper';
import getEntryPoint from './get-entry-point';

const useMixpanel = ({
  mixpanelToken,
  userId,
  sessionId,
}: {
  mixpanelToken: string;
  userId?: string;
  sessionId?: string;
}): boolean => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!mixpanelToken) {
      return undefined;
    }

    initMixpanel(mixpanel, mixpanelToken, {
      userId,
      sessionToken: sessionId,
    });

    mixpanel.register({
      siteSection: 'Owners',
      deviceCategory: getDeviceType(window?.screen?.width),
      entryPoint: getEntryPoint(),
    });

    setIsInitialized(true);

    return () => {
      mixpanel.unregister('entryPoint');
    };
  }, [mixpanelToken, sessionId, userId]);

  return isInitialized;
};

export default useMixpanel;
